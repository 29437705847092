<template>
  <MXSplitButton :actions="actions" variant="primary" @click="goToProductCreator()">
    Criar novo produto
  </MXSplitButton>
</template>

<script>
import MXSplitButton from '@/shared/components/MXSplitButton.vue';

export default {
  name: 'CreateProductButton',
  components: {
    MXSplitButton,
  },
  data() {
    return {
      actions: [
        {
          label: 'Curso online',
          icon: 'fal fa-play-circle',
          onClick: () => {
            this.$router.push({ name: 'ProductCreator', query: { type: 'online_course' } });
          },
        },
        {
          label: 'Ebook',
          icon: 'fal fa-books',
          onClick: () => {
            this.$router.push({ name: 'ProductCreator', query: { type: 'ebook' } });
          },
        },
        ...(this.$FCL.canLiveMeet()
          ? [
              {
                label: 'Ao vivo',
                icon: 'fal fa-wifi',
                onClick: () => {
                  this.$router.push({ name: 'ProductCreator', query: { type: 'lives' } });
                },
              },
            ]
          : []),
        {
          label: 'Arquivo',
          icon: 'fal fa-box-open',
          onClick: () => {
            this.$router.push({ name: 'ProductCreator', query: { type: 'file' } });
          },
        },
        ...(this.$FCL.canCreateComboProduct()
          ? [
              {
                label: 'Combo',
                icon: 'fal fa-th',
                onClick: () => {
                  this.$router.push({ name: 'ProductCreator', query: { type: 'path' } });
                },
              },
            ]
          : []),
      ],
    };
  },
  methods: {
    goToProductCreator() {
      this.$router.push({ name: 'ProductCreator' });
    },
  },
};
</script>
