<template>
  <div class="mx-split-button-container">
    <div class="mx-split-button-wrapper">
      <button
        v-bind="[$attrs, $props]"
        v-on="$listeners"
        :disabled="disabled"
        :class="['mx-split-button', `mx-split-button--${variant}`]"
      >
        <div class="mx-split-button__content-wrapper">
          <i v-if="icon" :class="icon" class="mx-split-button__icon" />
          <span v-if="$slots.default" class="mx-split-button__text">
            <slot />
          </span>
        </div>
      </button>

      <div class="mx-split-button__dropdown-wrapper">
        <button
          :class="['mx-split-button', 'mx-split-button--dropdown', `mx-split-button--dropdown-${variant}`]"
          :disabled="disabled"
          @click="toggleDropdown"
        >
          <i class="mx-split-button__icon fal fa-chevron-down" :class="{ 'mx-split-button__icon--rotate': isOpen }"></i>
        </button>
      </div>
    </div>

    <div v-show="isOpen && actions.length > 0" class="mx-split-button__menu">
      <div
        v-for="(action, index) in actions"
        :key="index"
        class="mx-split-button__menu-item"
        @click="handleActionClick(action)"
      >
        <i v-if="action.icon" :class="action.icon" class="mx-split-button__menu-item-icon"></i>
        {{ action.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MXSplitButton',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: variant => {
        return ['primary', 'secondary', 'tertiary'].includes(variant);
      },
    },
    actions: {
      type: Array,
      required: true,
      validator: value => {
        return value.every(
          action => typeof action === 'object' && action.label && typeof action.onClick === 'function'
        );
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    handleActionClick(action) {
      action.onClick();
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mx-split-button-wrapper {
  display: flex;
}

.mx-split-button {
  align-items: center;
  border: none;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  line-height: 150%;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: top;
  height: 40px;
  width: 100%;
  padding: 8px 16px;

  @media (min-width: 768px) {
    width: fit-content;
  }
}

.mx-split-button--dropdown {
  padding: 8px 12px;
  border-radius: 0 4px 4px 0;
}

.mx-split-button--primary {
  background: #7427f1;
  color: #fff;

  &:hover {
    background: #4d1aa1;
  }

  &:focus {
    background: #4d1aa1;
    outline: 2px solid #3575d4;
    outline-offset: 1px;
  }

  &:disabled {
    pointer-events: none;
    background: #cfcfcf;
    color: #6f6f6f;

    & > * {
      color: #6f6f6f;
    }
  }
}

.mx-split-button--dropdown-primary {
  border-left: 2px solid #4d1aa1;
  background: #7427f1;
  color: #fff;

  &:hover {
    background: #4d1aa1;
  }

  &:focus {
    background: #4d1aa1;
    outline: 2px solid #3575d4;
    outline-offset: 1px;
  }

  &:disabled {
    border-left: 2px solid #6f6f6f;
    pointer-events: none;
    background: #cfcfcf;
    color: #6f6f6f;

    & > * {
      color: #6f6f6f;
    }
  }
}

.mx-split-button--secondary {
  background: transparent;
  color: #7427f1;
  border-top: 2px solid #7427f1;
  border-left: 2px solid #7427f1;
  border-bottom: 2px solid #7427f1;

  &:hover {
    background: #ead3fe;
  }

  &:focus {
    background: #ead3fe;
    outline: 2px solid #3575d4;
    outline-offset: 1px;
  }

  &:disabled {
    pointer-events: none;
    color: #6f6f6f;
    border-top: 2px solid #cfcfcf;
    border-left: 2px solid #cfcfcf;
    border-bottom: 2px solid #cfcfcf;

    & > * {
      color: #6f6f6f;
    }
  }
}

.mx-split-button--dropdown-secondary {
  background: transparent;
  color: #7427f1;
  border: 2px solid #7427f1;
  border-right: 2px7f1;

  &:hover {
    background: #ead3fe;
  }

  &:focus {
    background: #ead3fe;
    outline: 2px solid #3575d4;
    outline-offset: 1px;
  }

  &:disabled {
    pointer-events: none;
    color: #6f6f6f;
    border: 2px solid #cfcfcf;

    & > * {
      color: #6f6f6f;
    }
  }
}

.mx-split-button--tertiary {
  background: transparent;
  color: #7427f1;

  &:hover {
    color: #4d1aa1;
    background: #efefef;
  }

  &:focus {
    color: #4d1aa1;
    outline: 2px solid #3575d4;
    outline-offset: 1px;
  }

  &:disabled {
    pointer-events: none;
    color: #6f6f6f;

    & > * {
      color: #6f6f6f;
    }
  }
}

.mx-split-button--dropdown-tertiary {
  background: transparent;
  color: #7427f1;

  &:hover {
    color: #4d1aa1;
    background: #efefef;
    border-left: 2px solid #cfcfcf;
  }

  &:focus {
    color: #4d1aa1;
    outline: 2px solid #3575d4;
    outline-offset: 1px;
  }

  &:disabled {
    pointer-events: none;
    color: #6f6f6f;

    & > * {
      color: #6f6f6f;
    }
  }
}

.mx-split-button__content-wrapper {
  display: flex;
  column-gap: 8px;
  align-items: center;
}

.mx-split-button__icon {
  font-size: 1rem;
}

.mx-split-button--block {
  width: 100%;
}

.mx-split-button__icon {
  transition: transform 0.3s ease-in-out;
}

.mx-split-button__icon--rotate {
  transform: rotate(180deg);
}

.mx-split-button-container {
  position: relative;
  width: 100%;
}

.mx-split-button__menu {
  position: absolute;
  right: 0;
  margin-top: 4px;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 50;
  min-width: 100%;
  width: fit-content;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mx-split-button__menu-item {
  padding: 4px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.313rem;
  color: #262626;
  border-radius: 4px;

  &:hover {
    background-color: #f3ebfa;
    color: #7427f1;
  }
}

.mx-split-button__menu-item-icon {
  width: 16px;
  text-align: center;
  margin-right: 4px;
}
</style>
